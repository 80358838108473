<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        >
          <logo class="w-10 mr-4 fill-current text-primary" />
          <span class="vx-logo-text text-primary">VCarPark</span>
        </router-link>

        <!-- <search-bar class="mr-4" /> -->

        <!-- <notification-drop-down /> -->

        <mannul-drop-down />

        <profile-drop-down />
      </vs-navbar>

      <vs-navbar
        v-if="isHomeUser"
        class="k-top-menu justify-center "
        vs-align="center"
        style="text-align: center"
      >
        <vs-navbar-item
          index="0"
          v-if="user_cloud_config != null"
        >
          <router-link
            tag="div"
            class="vx-logo cursor-pointer flex items-center"
            to="/home-user-news"
          >
            <div class="k-icon-box">
              <div slot="media" class="k-icon">
                <img src="@/assets/images/k/k-news-icon.png" />
              </div>
              <div class="k-label">
                <span><h5>ข่าวสาร</h5></span>
              </div>
            </div>
            <!-- <a href="#">News</a> -->
          </router-link>
        </vs-navbar-item>

        <vs-navbar-item
          index="1"
          v-if="
            user_cloud_config != null
          "
        >
          <router-link
            tag="div"
            class="vx-logo cursor-pointer flex items-center"
            to="/close-house"
          >
            <div class="k-icon-box">
              <div slot="media" class="k-icon">
                <img src="@/assets/images/k/k-open-close-home-icon.png" />
              </div>
              <div class="k-label">
                <span><h5>เปิดปิดบ้าน</h5></span>
              </div>
            </div>
          </router-link>
        </vs-navbar-item>

        <vs-navbar-item
          index="2"
          v-if="
            user_cloud_config != null
          "
        >
          <router-link
            tag="div"
            class="vx-logo cursor-pointer flex items-center"
            to="/stamp"
          >
            <div class="k-icon-box">
              <div slot="media" class="k-icon">
                <img src="@/assets/images/k/k-stamp-icon.png" />
              </div>
              <div class="k-label">
                <span><h5>ประทับตรา</h5></span>
              </div>
            </div>
          </router-link>
        </vs-navbar-item>
        <vs-navbar-item
          index="3"
          v-if="user_cloud_config != null"
        >
          <router-link
            tag="div"
            class="vx-logo cursor-pointer flex items-center"
            to="/home-user-vip"
          >
            <div class="k-icon-box">
              <div slot="media" class="k-icon">
                <img src="@/assets/images/k/k-fix-icon.png" />
              </div>
              <div class="k-label">
                <span><h5>แขกวีไอพี</h5></span>
              </div>
            </div>
          </router-link>
        </vs-navbar-item>
        <vs-navbar-item
          index="4"
          v-if="user_cloud_config != null"
        >
          <router-link
            tag="div"
            class="vx-logo cursor-pointer flex items-center"
            to="/house-request-help"
          >
            <div class="k-icon-box">
              <div slot="media" class="k-icon">
                <img src="@/assets/images/k/k-chat-icon.png" />
              </div>
              <div class="k-label">
                <span><h5>เรียกใช้บริการ</h5></span>
              </div>
            </div>
          </router-link>
        </vs-navbar-item>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
// import Bookmarks            from './components/Bookmarks.vue'
// import SearchBar            from './components/SearchBar.vue'
import Vue from "vue";
import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import MannulDropDown from "./components/MannulDropDown.vue";
import Logo from "../Logo.vue";
import { db, firebaseApp } from "../../../store/firebaseDb";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

export default {
  name: "the-navbar-horizontal",
  data() {
    return {
      activeClass: "selected-menu",
      user_cloud_config: {},
      menuActive: false
    };
  },
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true,
    },
  },
  components: {
    Logo,
    // Bookmarks,
    // SearchBar,
    NotificationDropDown,
    ProfileDropDown,
    MannulDropDown,
  },
  beforeCreate() {
    console.log("[[beforeCreate Hori]]");
  },
  created() {
    this.user_cloud_config = $cookies.get("user_cloud_config");
    this.startInterval();
  },
  computed: {
    isHomeUser(){
      this.hNumber = $cookies.get("home");
      var _isHomeUser = false
      var _length = (this.hNumber+"").length
      if( this.hNumber != null && _length >= 1){
        _isHomeUser = true;
      }

      console.log("_isHomeUser="+_isHomeUser);

      return _isHomeUser
    },
    currentPage() {
      const currentRoute = this.$router.currentRoute.path;
      console.log("currentRoute=" + currentRoute);
      return this.$router.currentRoute.path;
    },
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") color = "#f7f7f7";
      else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      if (this.isThemedark === "dark") {
        if (color === "#fff") {
          color = "#10163a";
        } else {
          color = "#262c49";
        }
      }

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    startInterval() {
      setInterval(() => {
        this.user_cloud_config = $cookies.get("user_cloud_config");
      }, 2000);
    },
    checkMenu(checktext) {
      const currentUrl = this.$router.currentRoute;
      console.log("currentUrl=", currentUrl);

      if (this.$router.currentRoute.path == checktext) return true;

      return false;
    },
  },
};
</script>


<style>
.k-top-menu {
  padding: 10px 0 10px 0;
  background-color: white !important;
  text-align: center;
}

.k-top-menu .k-icon-box {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 10px;
}
.k-top-menu .k-label {
  text-align: center;
}

.k-top-menu img {
  width: 50px;
}

.k-top-menu .con-vs-card {
  margin: 0px;
}

.selected-menu h5 {
  color: #7367ef;
  border-bottom: 4px solid;
}
</style>

/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/manage_dashboard",
    name: "Dashboard",
    slug: "manage_dashboard",
    icon: "MonitorIcon",
  },
  {
    url: "/manage_news",
    name: "จัดการข่าวสาร",
    slug: "manage_news",
    icon: "RssIcon",
  },
  {
    url: "/manage_home",
    name: "จัดการลูกบ้าน",
    slug: "manage_home",
    icon: "HomeIcon",
  },
  {
    url: "/manage_transactions",
    name: "ข้อมูลรถเข้า-ออก",
    slug: "manage_transactions",
    icon: "TruckIcon",
  },
  {
    url: "/manage_close_home",
    name: "ข้อมูลแจ้งปิดบ้าน",
    slug: "manage_close_home",
    icon: "ShieldIcon",
  },
  {
    url: "/manage_vip",
    name: "ข้อมูลแขกวีไอพี",
    slug: "manage_vip",
    icon: "UserCheckIcon",
  },
  {
    url: "/manage_request_help",
    name: "ข้อมูลเรียกใช้บริการ",
    slug: "manage_request_help",
    icon: "PhoneCallIcon",
  },
  // {
  //   url: "/home_user_dash",
  //   name: "แดชบอร์ด",
  //   slug: "home",
  //   icon: "HomeIcon",
  // },
  // {
  //   url: "/home_user_news",
  //   name: "ข่าวสาร",
  //   slug: "home_user_news",
  //   icon: "BellIcon",
  // },
  // {
  //   url: "/home_user_stamp",
  //   name: "ประทับตรา",
  //   slug: "home_user_stamp",
  //   icon: "UserCheckIcon",
  // },

  // {
  //   url: "/home_user_home_config",
  //   name: "เปิดปิดบ้าน",
  //   slug: "home_user_home_config",
  //   icon: "ShieldIcon",

  // },
  // {
  //   url: "/home_user_fix",
  //   name: "แจ้งซ่อม",
  //   slug: "home_user_fix",
  //   icon: "ToolIcon",
  // },
  // {
  //   url: "/home_user_fix",
  //   name: "สนทนา",
  //   slug: "home_user_fix",
  //   icon: "MessageCircleIcon",
  // }

]
